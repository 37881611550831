(function () {
    "use strict";

    angular
        .module("common.directives",
                [])

        .directive('equalizer', ['$window', '$timeout', function($window, $timeout) {
            return {
                restrict: 'A',
                link: function (scope, element, attr) {
                    var win = angular.element($window),
                        watchElements = angular.element(element).find('[equalizer-watch]'),
                        fnAction;

                    fnAction = function (value) {
                        watchElements.outerHeight(value);
                    };

                    function setHeight() {
                        var height = 0;
                        fnAction('auto');
                        for (var i = 0; i < watchElements.length; i++){
                            var elemHeight = watchElements.eq(i).outerHeight(true);
                            if (elemHeight > height){
                                height = elemHeight;
                            }
                        }
                        fnAction(height);
                    }

                    win.on('resize', setHeight);
                    $timeout(setHeight, 100);
                    $timeout(setHeight, 500);
                }
            }
        }])

        .directive('rnCarouselAutoHeight', [function() {
            function link(scope, element, attrs) {
                // console.log(scope.carouselIndex);
                function firstChildHeight() {
                    return element.children().children()[scope.carouselIndex].offsetHeight;
                }
                scope.$watch(firstChildHeight, function(height) {
                    element.css('height', height + 'px');
                });
            }
            return {
                restrict: 'A',
                link: link,
                priority: 100
            };
        }])
        .directive('rnCarouselHeight', [function() {
            function link(scope, element, attrs) {
                function firstChildHeight() {
                    return element.children().children()[scope.carouselIndex].offsetHeight;
                }
                scope.$watch(firstChildHeight, function(height) {
                    var height = 0;
                    angular.forEach(element.children(), function(elem) {
                        var elemHeight = angular.element(elem).children()[0].offsetHeight;
                        if (height < elemHeight) {
                            height = elemHeight
                        }
                    });
                    element.css('height', height + 'px');
                });
            }
            return {
                restrict: 'A',
                link: link,
                priority: 100
            };
        }]);

}());