(function () {
    "use strict";

    angular
        .module("hitte")
        .component("feedbackSend", {
            controller: ["$scope",
                "productsService",
                'dataResource',
                "$attrs",
                feedbackSend],
            controllerAs: 'vm',
            templateUrl: '/tpl/feedbackSend.html'
        });

    function feedbackSend($scope, productsService, dataResource, $attrs) {
        var vm      = this;
        vm.feedback = {};

        init();

        function init() {

        }

        vm.sendFeedback = function() {
            if(vm.feedback){
                dataResource.save({
                    action: 'post',
                    action2: 'feed'
                }, vm.feedback, function(response) {
                    if (response.success) {
                        vm.feedback = {};
                    } else {

                    }
                });
            }
        }

    }


}());