(function () {
    "use strict";

    angular
        .module("common.filters",
                [])

        .filter("tagFormat", ["$sce", function($sce) {
            return function(text, leave) {
                if (text) {
                    var textObj = text.split(leave),
                        result = [];
                    angular.forEach(textObj, function(v) {
                        if (v.length > 0) {
                            result.push('<strong>' + v + '</strong>');
                        } else {
                            result.push('');
                        }
                    });

                    return $sce.trustAsHtml(result.join(leave));
                }
            };
        }])
        .filter("htmls", ["$sce", function($sce) {
            return function(text) {
                if (text) {
                    return $sce.trustAsHtml(text);
                }
            };
        }])
        .filter("declOfNum", function() {
            return function(number, titles) {
                //titles: 1,2,5
                var cases = [2, 0, 1, 1, 1, 2];
                return titles [
                    (number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]
                ];
            };
        })

        .filter('crop', function() {
            return function(input, limit, respectWordBoundaries, suffix){
                if (input === null || input === undefined || limit === null || limit === undefined || limit === '') {
                    return input;
                }
                if (angular.isUndefined(respectWordBoundaries)) {
                    respectWordBoundaries = true;
                }
                if (angular.isUndefined(suffix)) {
                    suffix = '...';
                }

                if (input.length <= limit) {
                    return input;
                }

                limit = limit - suffix.length;

                var trimmedString = input.substr(0, limit);
                if (respectWordBoundaries) {
                    return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))) + suffix;
                }
                return trimmedString + suffix;
            }
        });

}());