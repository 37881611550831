(function () {
    "use strict";

    angular
        .module("hitte")
        .component("callmeSend", {
            controller: ["$scope",
                "productsService",
                'dataResource',
                "$attrs",
                callmeSend],
            controllerAs: 'vm',
            templateUrl: '/tpl/callmeSend.html'
        });

    function callmeSend($scope, productsService, dataResource, $attrs) {
        var vm   = this;

        init();

        function init() {

        }

        vm.callmeSend = function() {
            if(vm.callme){
                dataResource.save({
                    action: 'post',
                    action2: 'call'
                }, vm.callme, function(response) {
                    if (response.success) {
                        vm.callme = {};
                    } else {

                    }
                });
            }
        }

    }


}());