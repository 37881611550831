(function () {
    "use strict";

    angular
        .module("common.services")
        .factory("productsService",
                ["dataResource",
                "$q",
                userService]);

    function userService(dataResource, $q) {
        var _products       = undefined,
            _params         = undefined;

        //     _authenticated = false;

        return {
            getParams: function(product_id) {
                var deferred = $q.defer();

                if (angular.isDefined(_params)) {
                    deferred.resolve(_params);

                    return deferred.promise;
                }
                dataResource.query({
                    action: 'getParams',
                    id: product_id
                }, function(response) {
                    if(response.success) {
                        _params = response.data;
                        deferred.resolve(_params);
                    }
                });

                return deferred.promise;
            },
            getProducts: function(product_id) {
                var deferred = $q.defer();

                if (angular.isDefined(_products)) {
                    deferred.resolve(_products);

                    return deferred.promise;
                }
                dataResource.query({
                    action: 'getProducts',
                    id: product_id
                }, function(response) {
                    _products = response;
                    deferred.resolve(_products);
                });

                return deferred.promise;
            }
        };
    }
}());

