(function () {
    "use strict";
    angular
        .module("hitte",
            ["common.services",
            "common.filters",
            "common.directives"])

        .config(["$interpolateProvider", function($interpolateProvider) {
            $interpolateProvider.startSymbol('{[').endSymbol(']}');
        }]);
}());