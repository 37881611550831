(function () {
    "use strict";

    angular
        .module("common.services")
        .factory("dataResource",
                ["$resource",
                "$http",
                 dataResource])
        .config(['$httpProvider', function($httpProvider) {
            /*$httpProvider.defaults.headers.common = {'X-Requested-With': 'XMLHttpRequest'};
            // $httpProvider.defaults.headers.post = {'Content-Type': 'application/x-www-form-urlencoded'};
            $httpProvider.defaults.transformRequest.unshift(function (data, headersGetter) {
                var param = angular.element('meta[name="csrf-param"]').attr("content"),
                    token = angular.element('meta[name="csrf-token"]').attr("content");

                if (data && param && token) {
                    data[param] = token;
                }

                return data;
            });*/
        }]);

        function dataResource($resource, $http) {
            return $resource('/ajax/:action/:action2/:action3/:id');
        }
}());