(function () {
    "use strict";

    angular
        .module("hitte")
        .component("productsList", {
            controller: ["$scope",
                "productsService",
                'dataResource',
                "$attrs",
                productsList],
            controllerAs: 'vm',
            templateUrl: '/tpl/productsList.html'
        });

    function productsList($scope, productsService, dataResource, $attrs) {
        var vm   = this;
        vm.width = '';

        $scope.$root.isProductsLoading = true;

        init();

        function init() {

            dataResource.get({
                action: 'getParams',
                id: $attrs.product
            },  function(response) {
                if (response.success) {
                    vm.params = response.data;
                } else {
                    vm.params = {}
                }
            });

            dataResource.get({
                action: 'getProducts',
                id: $attrs.product
            },  function(response) {
                if (response.success) {
                    vm.products = response.data;
                    vm.filter   = response.data;
                } else {
                    vm.products = {}
                }
            });

        }

        vm.Update = function() {
            //init();
            vm.filter = {};
            dataResource.get({
                action: 'getProducts',
                id: $attrs.product
            },  function(response) {

                if (response.success) {
                    vm.products = response.data;
                    vm.filter   = response.data;
                   // console.log(vm.filter);
                    angular.forEach(vm.filter, function(v, k) {
                        var _remove = false;
                        if(vm.width) {
                            if(v.product_width_id  != vm.width){
                               _remove = true;
                            }
                        }
                        if(vm.height) {
                            if(v.product_height_id  != vm.height){
                                _remove = true;
                            }
                        }
                        if(vm.length) {
                            if(v.product_length_id  != vm.length){
                                _remove = true;
                            }
                        }

                        if(vm.type) {
                            if(v.type  != vm.type){
                                _remove = true;
                            }
                        }

                        if(vm.power) {
                            if((v.product_power_value < vm.params.power[vm.power].product_power_start) || (v.product_power_value > vm.params.power[vm.power].product_power_end) )
                                _remove = true;
                        }
                        if(_remove) {
                           delete vm.filter[k];
                        }
                    });
                } else {
                    vm.filters = {}
                }
            });
          //  console.log(vm.filter);
        }

    }


}());