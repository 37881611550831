(function () {
    "use strict";

    angular
        .module("hitte")
        .component("productFilters", {
            controller: ["$scope",
                "productsService",
                "$attrs",
                productFilters],
            controllerAs: 'vm',
            templateUrl: '/tpl/productFilters.html'
        });

    function productFilters($scope, productsService, $attrs) {
        var vm = this;

        $scope.$root.isFiltersLoading = true;
        vm.isFiltersLoading = true;

        vm.isFilterMenueOpen = false;

        init();

        function init() {
            productsService.getFilters($attrs.locale)
                .then(function(data) {
                    vm.filtersBlocks = data;
                    $scope.$root.isFiltersLoading = false;
                    vm.isFiltersLoading = false;
                });
        }

        vm.setFilter = function(filter) {
            var value = filter.isSelected;
            vm.filtersBlocks.forEach(function(group) {
                if (group.id == filter.filter_type_id) {
                    group.filter_values.forEach(function(filter) {
                        filter.isSelected = false;
                    });
                }
            });
            filter.isSelected = value;
        }

        vm.toggleFilters = function() {
            vm.isFilterMenueOpen = !vm.isFilterMenueOpen;
        }
    }

}());